import React from "react";
import styled from "styled-components";
import PhotoCard from "../components/PhotoCard";

export default function Slide_Beysu_2({ show }) {
  return (
    <Container show={show}>
      <PhotoCard
        top={false}
        label="Film gibi bir gün."
        mL="17vw"
        mT="13vh"
        date="3 Aralık 2023"
        img="/slides/photo_1.jpeg"
      />
      <PhotoCard
        top={true}
        label="Semoşu burada verdim. İkonik"
        mL="34.5vw"
        mT="45vh"
        date="13 Temmuz 2023"
        img="/slides/photo_3.jpeg"
      />
      <PhotoCard
        top={true}
        label="Beraber içmiştik. İlk defa birine bu kadar yakın hissetmiştim."
        mL="51.9vw"
        mT="45vh"
        date="13 Temmuz 2023"
        img="/slides/photo_2.jpeg"
      />
      <Location>Beysupark, Ankara</Location>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) =>
    props.show ? "translateY(0px)" : "translateY(50px)"};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
`;
