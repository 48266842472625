import React from "react";
import styled from "styled-components";

export default function BannerPhoto({ src, label }) {
  return (
    <Container>
      <Rotator>
        <Image src={src} />
        <Tint>
          <Text>{label}</Text>
        </Tint>
      </Rotator>
    </Container>
  );
}

const Container = styled.div``;
const Text = styled.body`
  font-size: 4vh;
  text-align: center;
  font-weight: bold;
  transform: rotateX(180deg);
`;
const Image = styled.img`
  height: 40vh;
  border-radius: 2vh;
  border-style: solid;
  border-width: 0.4vh;
  border-color: whitesmoke;
`;
const Tint = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2vh;
  background-color: white;
  transition: all 0.2s;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Container}:hover & {
    opacity: 1;
  }
`;
const Rotator = styled.div`
  transform: perspective(1500px) rotateX(0deg) rotateY(15deg);
  transition: transform 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  ${Container}:hover & {
    transform: perspective(3000px) rotateX(180deg) rotateY(15deg);
  }
`;
