import React from "react";
import styled from "styled-components";

export default function Slide_End_2({ show }) {
  return (
    <Container show={show}>
      <Title>
        Senin saçının teline, gülüşüne, göz yaşına, dudaklarına, ses tonuna,
        burnuna, gözlerine, yürüyüşüne, davranışlarına alımlılığına ve geri
        kalan her şeyine çok değer veriyorum. Sen benimsin ve benim olarak
        kalacaksın. Sonsuza kadar...
      </Title>
      <Image src="slides/photo_end.png" />
      <Subtitle>Literally Dünyamsın.</Subtitle>
    </Container>
  );
}

const Image = styled.img`
  object-fit: cover;
  position: absolute;
  width: 55vh;
  transform: translate(0, 50%);
  opacity: 0.7;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  top: 0;
  left: 0;
  margin-top: 35vh;
  margin-left: 7vh;
  position: absolute;
  color: whitesmoke;
  font-size: 3vh;
  width: 30%;
  text-align: left;
  text-shadow: 0px 1px 65px #000;
  font-weight: 100;
  font-family: system-ui;
`;
const Subtitle = styled.body`
  color: whitesmoke;
  font-size: 3vh;
  font-weight: 300;
  width: 100%;
  text-align: center;
  margin-top: 88vh;
  opacity: 0.9;
`;
