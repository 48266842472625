import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function SlapPhoto({ ndx, img, label, relNdx }) {
  const [deg, setDeg] = useState("0deg");
  const [x, setX] = useState(Math.random() > 0.5 ? "-54vw" : "54vw");
  const [y, setY] = useState("105vh");
  const [scale, setScale] = useState("25");
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (relNdx == ndx) {
      let _x = Math.random() > 0.5 ? "-54vw" : "54vw";
      setX(_x);
      setScale("25");
      setOpacity(0);
      let a = Math.random() * 15 - 7.5;
      setDeg(a.toString() + "deg");
    } else if (ndx + 1 == relNdx) {
      setX("-10vw");
      setScale(1.2 - ndx / 40);
      setY("35vh");
      setOpacity(1);
    }
  }, [relNdx]);

  return (
    <Container
      opacity={opacity}
      translate={
        "translate(" +
        x +
        ", " +
        y +
        ") rotate(" +
        deg +
        ") scale(" +
        scale +
        ")"
      }
    >
      <Image src={img} />
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  transform: ${(props) => props.translate};
  transform-origin: center right;
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in;
  box-shadow: 0px 0px 25px rgba(5, 5, 5, 0.3);
`;
const Image = styled.img`
  height: 45vh;
  border-radius: 2vh;
  border-width: 0.7vh;
  border-color: whitesmoke;
  border-style: solid;
`;
