import React from "react";
import styled from "styled-components";
import BannerPhoto from "../components/BannerPhoto";

export default function Slide_Ata_1({ show }) {
  const imgs = [
    { src: "/slides/photo_32.jpeg", label: "Jimnastik birey" },
    { src: "/slides/photo_33.jpeg", label: "Çok güzel bi fotomuz" },
    { src: "/slides/photo_34.jpeg", label: "Gülüşünü yerler" },
    { src: "/slides/photo_48.jpeg", label: "Cilve yapıyo" },
    { src: "/slides/photo_35.jpeg", label: "Uykucuyuzdur" },
    { src: "/slides/photo_46.jpeg", label: "Tipini yerim" },
    { src: "/slides/photo_36.jpeg", label: "Uykucusundur" },
    { src: "/slides/photo_38.jpeg", label: "Gülüş ısırmalık" },
    { src: "/slides/photo_39.jpeg", label: "Çok marjinal bi foto" },
    { src: "/slides/photo_41.jpeg", label: "Ayin yapan yamyam" },
    { src: "/slides/photo_42.jpeg", label: " Semos + Love" },
    {
      src: "/slides/photo_43.jpeg",
      label: "Fit checklere bayılırım",
    },
    { src: "/slides/photo_49.jpeg", label: "En güzel fotomuz" },
    { src: "/slides/photo_44.jpeg", label: "Yerde uyumaca" },
    { src: "/slides/photo_37.jpeg", label: "Bakış ısırmalık" },
    { src: "/slides/photo_45.jpeg", label: "Beşiktaş fanı birey" },
    { src: "/slides/photo_40.jpeg", label: "Dürüm bade" },
    { src: "/slides/photo_47.jpeg", label: "Mafyatiğizdir" },
  ];

  return (
    <Container show={show}>
      <Title>Bu biz</Title>
      <BannerContainer>
        {imgs.map((a) => {
          return <BannerPhoto key={a.label} src={a.src} label={a.label} />;
        })}
      </BannerContainer>
      <Location>Ata Bilge Aka, Ankara</Location>
    </Container>
  );
}

const BannerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vh;
  row-gap: 1vh;
  column-gap: 1vh;
  flex-wrap: wrap;
  overflow: scroll;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
`;
const Title = styled.body`
  color: whitesmoke;
  font-size: 5vh;
  text-align: center;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
`;
