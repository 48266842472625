import React from "react";
import styled from "styled-components";

export default function Slide_End_1({ show }) {
  return (
    <Container show={show}>
      <Title>Yani anlayacağın, benim dünyamsın. Seni seviyorum.</Title>
      <Subtitle>
        Birbizimizi asla bırakmayacağımız 10000 yıl daha olması dileğiyle,
        prensesim.❤️
      </Subtitle>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  margin-top: 25px;
  color: whitesmoke;
  font-size: 8vh;
  width: 100%;
  text-align: center;
  text-shadow: 0px 1px 65px #000;
  font-weight: 500;
`;
const Subtitle = styled.body`
  color: whitesmoke;
  font-size: 3vh;
  font-weight: 300;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  opacity: 0.9;
`;
