import styled from "styled-components";
import React from "react";

export default function PhotoCard({ label, img, date, mL, mT, top }) {
  return (
    <PhotoContainer style={{ marginLeft: mL, marginTop: mT }}>
      <Label>{date}</Label>
      <Photo src={img} />
      <Label>{label}</Label>
      <Dot top={top}></Dot>
    </PhotoContainer>
  );
}

const Photo = styled.img`
  display: flex;
  align-items: center;
  width: 26vh;
  border-width: 0.4vh;
  object-fit: contain;
  justify-content: center;
  border-style: double;
  border-radius: 12px;
`;
const Label = styled.body`
  text-align: center;
  font-size: 1.5vh;
  color: black;
  margin-top: 0.7vh;
  margin-bottom: 0.7vh;
  max-width: 13vw;
  font-weight: 500;
`;
const PhotoContainer = styled.div`
  user-select: none;
  border-radius: 20px;
  flex-direction: column;
  padding: 0.7vh;
  background-color: whitesmoke;
  display: flex;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;
  border-style: solid;
  position: absolute;
  border-width: 0.4vh;
  left: 0;
  &:hover {
    transform: scale(1.2);
  }
`;
const Dot = styled.div`
  background-color: whitesmoke;
  width: 3vh;
  border-style: solid;
  border-radius: 5px;
  z-index: -100;
  border-width: 0.4vh;
  position: absolute;
  transform: rotate(45deg);
  aspect-ratio: 1;
  bottom: ${(props) => !props.top && 0};
  top: ${(props) => props.top && 0};
  margin-bottom: ${(props) => !props.top && "-2vh"};
  transition: opacity 0.1s;
  margin-top: ${(props) => props.top && "-2vh"};

  ${PhotoContainer}:hover & {
    opacity: 0;
    border-style: none;
  }
`;
