import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import SmallCarouselPhoto from "../components/SmallCarouselPhoto";

export default function Slide_Home_1({ show, ndx }) {
  const imgs = [
    {
      img: "/slides/photo_50.jpeg",
      label: "Hep beraber gülelim.",
    },
    {
      img: "/slides/photo_51.jpeg",
      label: "Tipini sevdiğim. Hottie Baddie",
    },
    {
      img: "/slides/photo_52.jpeg",
      label: "Şoför hanım.",
    },
    {
      img: "/slides/photo_53.jpeg",
      label: "Gülüşe bak. Aşık olma da ne yap.",
    },
    {
      img: "/slides/photo_54.jpeg",
      label: "Küpeğimle aşkım beraber",
    },
    {
      img: "/slides/photo_55.jpeg",
      label:
        "Çok mistik bi foto ama hep bu kadar güzel olalım. (Acayip kötü çıkmışım)",
    },
    {
      img: "/slides/photo_56.jpeg",
      label: "Hep bizde kalsan ne olur",
    },
    {
      img: "/slides/photo_57.jpeg",
      label: "Aynı zamanda obursundur da.",
    },
    {
      img: "/slides/photo_58.jpeg",
      label: "Çok alımlısın Bayılıyorum.",
    },
    {
      img: "/slides/photo_59.jpeg",
      label: "Spa date de yaptık.",
    },
    {
      img: "/slides/photo_60.jpeg",
      label: "Öpmelik, yüzündeki o tatlılık sadece benim.",
    },
    {
      img: "/slides/photo_61.jpeg",
      label: "Saçını da toplarmış. Mis gibi kokuyodur.",
    },
  ];

  const [clickNdx, setClickNdx] = useState(0);
  const [prevNdx, setPrev] = useState(-1);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    let a = (ndx - 1053) / 147;
    let b = 1 / imgs.length;
    let n = (a / b).toFixed();

    if (n >= 0) {
      setClickNdx(Math.abs(n));
    }
  }, [ndx]);

  useEffect(() => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 255);
    setTimeout(() => {
      setPrev(clickNdx);
    }, 510);
  }, [clickNdx]);

  return (
    <Container show={show}>
      <LabelContainer>
        <Title>Sen benim bitanemsin. Senden başkasını istemiyorum.</Title>
        <Subtitle>
          Hayat bir iyilik yaptı ve hayatıma senin gibi bi prenses yolladı.
        </Subtitle>
      </LabelContainer>
      <CarouselPhotoEnter
        clicked={clicked}
        src={clickNdx < imgs.length && clickNdx >= 0 && imgs[clickNdx].img}
      ></CarouselPhotoEnter>
      <Label>
        {clickNdx < imgs.length && clickNdx >= 0 && imgs[clickNdx].label}
      </Label>
      <CarouselPhotoExit
        clicked={clicked}
        src={prevNdx < imgs.length && prevNdx >= 0 && imgs[prevNdx].img}
      ></CarouselPhotoExit>
      <CarouselHolder>
        {imgs.map((a) => {
          return (
            <SmallCarouselPhoto
              key={Math.random()}
              ndx={imgs.indexOf(a)}
              onClick={setClickNdx}
              clickNdx={clickNdx}
              img={a.img}
            />
          );
        })}
      </CarouselHolder>
      <Location>Beysuvillakent2 Sitesi, Ankara</Location>
    </Container>
  );
}
const onEnter = keyframes`
  0%{
    transform: translateX(120vh);
  }
  100%{
    transform: translateX(0vh);
  }
`;
const onExit = keyframes`
  0%{
    transform: translateX(0vh);
  }
  100%{
    transform: translateX(-150vh);
  }
`;
const Label = styled.body`
  background-color: rgba(125, 125, 125, 0.7);
  border-radius: 15px;
  font-size: 4vh;
  color: whitesmoke;
  font-weight: 600;
  position: absolute;
  top: 0;
  margin-top: 44vh;
  margin-right: 10vw;
  padding: 1vh;
  right: 0;
  border-width: 0.5vh;
  border-style: dotted;
  max-width: 18vw;
`;
const CarouselPhotoExit = styled.img`
  height: 55vh;
  position: absolute;
  top: 0;
  margin-top: 22vh;
  z-index: 100;
  border-radius: 25px;
  border-style: dashed;
  transform: translateX(0vh);
  border-width: 0.5vh;
  border-color: whitesmoke;
  animation: ${(props) => !props.clicked && onExit} 0.25s ease-out;
`;
const CarouselPhotoEnter = styled.img`
  height: 55vh;
  position: absolute;
  top: 0;
  margin-top: 22vh;
  z-index: 150;
  border-radius: 25px;
  animation: ${(props) => props.clicked && onEnter} 0.25s ease-in;
  transition: all 0.2s;
  border-style: dashed;
  border-width: 0.5vh;
  transform: translateX(0vh);
  border-color: whitesmoke;
`;
const CarouselHolder = styled.div`
  padding: 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 6vh;
  column-gap: 1vh;
  background-color: rgba(226, 222, 216, 0.4);
`;
const Subtitle = styled.body`
  color: whitesmoke;
  font-size: 3.5vh;
  font-weight: 300;
  text-shadow: 0px 1px 50px #000;
  text-align: left;
  margin-top: 10px;
  opacity: 0.9;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
`;
const Title = styled.body`
  color: whitesmoke;
  font-size: 5vh;
  text-align: left;
  text-shadow: 0px 1px 50px #000;
  font-weight: 500;
`;
const LabelContainer = styled.div`
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: left;
  justify-content: center;
  margin-top: 2%;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1%;
`;
