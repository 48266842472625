import React from "react";
import styled from "styled-components";

export default function Slide_Beysu_Maya_1({ show }) {
  return (
    <Container show={show}>
      <Title>Şuan sensiz ne yaparım bilmiyorum bile.</Title>
      <Subtitle>
        İyi ki yolumuz kesişmiş ve hiç de kesişmeyi bırakmaz. Sen benim seni
        nasıl sevdiğimi anlayamazsın.
      </Subtitle>
      <Subtitle>
        Seni o kadar çok seviyorum ki bu dünyadaki bütün her şeyi topla bir
        pakete koy senin gülüşüne değişmem.
      </Subtitle>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  background-color: rgba(0, 0, 0, 0.35);
  margin-top: 0.5rem;
  color: whitesmoke;
  font-size: 7vh;
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 0.6rem;
`;
const Subtitle = styled.body`
  padding: 0.6rem;
  color: whitesmoke;
  font-size: 3vh;
  font-weight: 100;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  text-align: center;
`;
