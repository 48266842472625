import { useMotionValueEvent, useScroll, useTransform } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slide_Start_1 from "./Slides/Slide_Start_1";
import Slide_Start_2 from "./Slides/Slide_Start_2";
import Slide_Beysu_1 from "./Slides/Slide_Beysu_1";
import Slide_Beysu_2 from "./Slides/Slide_Beysu_2";
import Slide_Beysu_3 from "./Slides/Slide_Beysu_3";
import Slide_Beysu_4 from "./Slides/Slide_Beysu_4";
import Slide_Beysu_Maya_1 from "./Slides/Slide_Beysu_Maya_1";
import Slide_Maya_1 from "./Slides/Slide_Maya_1";
import Slide_Maya_BigBen from "./Slides/Slide_Maya_BigBen";
import Slide_End_1 from "./Slides/Slide_End_1";
import Slide_End_2 from "./Slides/Slide_End_2";
import Slide_BigBen_Ata from "./Slides/Slide_BigBen_Ata";
import Slide_Ata_1 from "./Slides/Slide_Ata_1";
import Slide_Home_1 from "./Slides/Slide_Home_1";

export default function App() {
  const ref = useRef(null);

  const [ndx, setNdx] = useState(0);
  const { scrollYProgress } = useScroll(ref);
  const currentIndex = useTransform(scrollYProgress, [0, 1], [0, 1411]);

  const slides = [
    <Slide_Start_1 show={ndx >= 0 && ndx < 40} />,
    <Slide_Start_2 show={ndx >= 40 && ndx <= 80} />,
    <Slide_Beysu_1 show={ndx >= 126 && ndx < 150} />,
    <Slide_Beysu_2 show={ndx >= 150 && ndx < 200} />,
    <Slide_Beysu_3 show={ndx >= 200 && ndx < 250} />,
    <Slide_Beysu_4 show={ndx >= 250 && ndx <= 300} />,
    <Slide_Beysu_Maya_1 show={ndx >= 322 && ndx <= 354} />,
    <Slide_Maya_1 show={ndx >= 358 && ndx <= 510} />,
    <Slide_Maya_BigBen show={ndx >= 515 && ndx <= 760} ndx={ndx} />,
    <Slide_BigBen_Ata show={ndx >= 778 && ndx <= 865} />,
    <Slide_Ata_1 show={ndx >= 870 && ndx <= 985} />,
    <Slide_Home_1 show={ndx >= 1053 && ndx <= 1200} ndx={ndx} />,
    <Slide_End_1 show={ndx >= 1290 && ndx <= 1411} />,
    <Slide_End_2 show={ndx >= 1400 && ndx <= 1411} />,
  ];

  const images = useMemo(() => {
    const loadedImages = [];

    for (let i = 1; i <= 1410; i++) {
      const img = new Image();
      let imname = "";
      if (i >= 1 && i < 10) {
        imname = "000" + i;
      } else if (i >= 10 && i <= 99) {
        imname = "00" + i;
      } else if (i >= 100 && i <= 999) {
        imname = "0" + i;
      } else if (i >= 1000) {
        imname = i.toString();
      }

      img.src = `/footage_webp/frame_${imname}.webp`;
      loadedImages.push(img);
    }
    return loadedImages;
  }, []);
  const renderCanvas = useCallback(
    (index) => {
      if (images[index - 1]) {
        ref.current?.getContext(`2d`)?.drawImage(images[index - 1], 0, 0);
      }
    },
    [images]
  );
  useMotionValueEvent(currentIndex, "change", (latest) => {
    renderCanvas(latest.toFixed());
    setNdx(latest.toFixed());
    console.log(latest.toFixed());
  });

  return (
    <MainContainer>
      <ScrollContainer>
        <ScrollCanvas ref={ref} width={1920} height={1080}></ScrollCanvas>
        <ScrollIndicatorContainer>
          <ScrollIndicator show={true}>▼ Aşağıya kaydır</ScrollIndicator>
        </ScrollIndicatorContainer>
        {slides.map((s) => {
          return s;
        })}
      </ScrollContainer>
    </MainContainer>
  );
}

const Wave = keyframes`
    0% {
        transform: translateY(1);
    }
    50% {
        transform: translateY(-25%);
    }
    100% {
        transform: translateY(1);
    }
`;

const MainContainer = styled.div`
  width: 100%;
`;
const ScrollContainer = styled.div`
  transition: all 0.4s;
  z-index: -99;
  width: 100%;
  height: 30000px;
  background-color: #000;
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
`;
const ScrollCanvas = styled.canvas`
  width: 100%;
  user-select: none;
  transition: max-height 0.4s;
  background-color: #000;
  z-index: 0;
  position: fixed;
`;
const ScrollIndicatorContainer = styled.div`
  width: 40%;
  height: auto;
  transition: all 0.2s;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 0;
  position: fixed;
`;
const ScrollIndicator = styled.p`
  user-select: none;
  padding: 0;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? "1" : "0")};
  color: whitesmoke;
  animation: ${Wave} 2s -2s ease infinite;
  font-size: 2vh;
`;
