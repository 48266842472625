import React from "react";
import styled, { keyframes } from "styled-components";
import CarouselPhoto from "./CarouselPhoto";

export default function Carousel({ imgs }) {
  return (
    <ScrollContainer>
      <Container>
        {imgs.map((a, i) => (
          <CarouselPhoto src={a.img} label={a.label} key={i} />
        ))}
      </Container>
    </ScrollContainer>
  );
}

const Swipe = keyframes`
    0% {
        transform: translateX(5%);
    }
    100% {
        transform: translateX(-130%);
    }
`;
const ScrollContainer = styled.div`
  position: absolute;
  transform: translate(0, 33vh);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;
const Container = styled.div`
  padding-top: 2.5vh;
  padding-left: 2.5vh;
  padding-bottom: 2.5vh;
  display: flex;
  column-gap: 1.2vh;
  animation: ${Swipe} 30s linear infinite alternate;
`;
