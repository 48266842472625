import React from "react";
import styled from "styled-components";

export default function Slide_Start_1({ show }) {
  return (
    <Container show={show}>
      <Title>Merhaba Badem,</Title>
      <Subtitle>
        Bugün 1. yılımız ve bu yıl hayatımın en iyi, en güzel yılıydı sayende.
        Seni çok seviyorum.
      </Subtitle>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: 25;
  display: flex;
  align-items: center;
  z-index: ${(props) => (props.show ? 25 : -25)};
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  margin-top: 0.5rem;
  color: whitesmoke;
  font-size: 8vh;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;
const Subtitle = styled.body`
  color: whitesmoke;
  font-size: 3vh;
  font-weight: 100;
  width: 100%;
  text-align: center;
  opacity: 0.8;
`;
