import React from "react";
import styled from "styled-components";

export default function CarouselPhoto({ src, label }) {
  return (
    <Container>
      <Image src={src} />
      <Label>{label}</Label>
    </Container>
  );
}

const Container = styled.div`
  padding: 1vh;
  background-color: whitesmoke;
  height: 40vh;
  border-radius: 1.5vh;
  box-shadow: none;
  user-select: none;
  transition: all 0.5s;
  overflow: visible;
  &:hover {
    background-color: transparent;
    padding: 0px;
    transform: scale(1.5);
    box-shadow: 0px 0px 100px rgb(5, 5, 5);
  }
`;
const Image = styled.img`
  height: 40vh;
  border-radius: 1vh;
  transition: all 0.5s;
  ${Container}:hover & {
    border-radius: 1.5vh;
  }
`;
const Label = styled.body`
  text-align: center;
  color: whitesmoke;
  font-weight: 500;
  opacity: 0;
  transition: all 0.5s;
  font-size: 3vh;
  ${Container}:hover & {
    opacity: 1;
  }
`;
