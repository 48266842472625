import React from "react";
import styled, { keyframes } from "styled-components";

export default function SmallCarouselPhoto({ ndx, onClick, clickNdx, img }) {
  return (
    <Container
      clicked={ndx === clickNdx}
      onClick={() => {
        onClick(ndx);
      }}
      src={img}
    ></Container>
  );
}

const onClickAnim = keyframes`
    0%{
        transform: scale(1.3) translateY(-2vh);
        z-index: 100;
        margin-right: 1vh;
        margin-left: 1vh;
    }
    100%{
        z-index: 101;
        transform: scale(1.3) translateY(-1.5vh);
        margin-right: 1vh;
        margin-left: 1vh;
    }
`;

const Container = styled.img`
  cursor: pointer;
  animation: ${(props) => props.clicked && onClickAnim} 0.3s forwards;
  pointer-events: ${(props) => (props.clicked ? "none" : "")};
  transition: all 0.2s;
  height: 10vh;
  border-radius: 15px;

  &:hover {
    transform: scale(1.3) translateY(-1.5vh);
    z-index: 100;
    margin-right: 1vh;
    margin-left: 1vh;
  }
`;
