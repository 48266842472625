import React from "react";
import styled from "styled-components";
import Carousel from "../components/Carousel";

export default function Slide_Maya_1({ show }) {
  const imgs = [
    { img: "/slides/photo_9.jpeg", label: "Tersten artistik bir çekim." },
    { img: "/slides/photo_10.jpeg", label: "O thumbı ıssırırım." },
    { img: "/slides/photo_11.jpeg", label: "O üzümü yediğin gibi seni yerim." },
    { img: "/slides/photo_12.jpeg", label: "Ağzını yerim" },
    { img: "/slides/photo_13.jpeg", label: "Gülüşünü de ısısısısrım." },
    { img: "/slides/photo_14.jpeg", label: "Gizli bir çekimim." },
    {
      img: "/slides/photo_15.jpeg",
      label: "Düşünceli bir bireysin aynı zamanda",
    },
    { img: "/slides/photo_16.jpeg", label: "Gülüşe bak allahım." },
    { img: "/slides/photo_17.jpeg", label: "Matching outfit" },
    { img: "/slides/photo_18.jpeg", label: "Eskilerden bi foto." },
    { img: "/slides/photo_19.jpeg", label: "Hiiiiiiiiiiiğ" },
    { img: "/slides/photo_20.jpeg", label: "En sevilen fotolardan biridir." },
    { img: "/slides/photo_21.jpeg", label: "Ve mezun olduk... Yine taşız" },
  ];

  return (
    <Container show={show}>
      <LabelContainer>
        <Title>
          Seni o kadar çok seviyordum ki okula gelmek için bir nedenim vardı.
        </Title>
      </LabelContainer>
      <Carousel imgs={imgs}></Carousel>
      <Location>Maya Fen Lisesi, Ankara</Location>
    </Container>
  );
}

const LabelContainer = styled.div`
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: left;
  justify-content: center;
  margin-top: 2%;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1%;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: 25;
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  color: whitesmoke;
  font-size: 5vh;
  text-align: left;
  text-shadow: 0px 1px 50px #000;
  font-weight: 500;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
`;
