import React from "react";
import styled from "styled-components";
import PhotoCard from "../components/PhotoCard";

export default function Slide_Beysu_4({ show }) {
  return (
    <Container show={show}>
      <PhotoCard
        top={true}
        label="İyi ki benimsin..."
        mL="17.2vw"
        mT="45vh"
        date="7 Ekim 2024"
        img="/slides/photo_7.jpeg"
      />
      <PhotoCard
        top={false}
        label="Çok romantik bir andı."
        mL="51.5vw"
        mT="0vh"
        date="23 Temmuz 2024"
        img="/slides/photo_6.jpeg"
      />
      <PhotoCard
        top={true}
        label="Böyle boş boş oturmalarımız en sevdiğim şeylerden biri."
        mL="42vw"
        mT="48.3vh"
        date="6 Nisan 2024"
        img="/slides/photo_8.jpeg"
      />
      <Location>Beysupark, Ankara</Location>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  transform: ${(props) =>
    props.show ? "translateY(0px)" : "translateY(-50px)"};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;

const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
  margin-top: 10px;
`;
