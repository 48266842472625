import React from "react";
import styled from "styled-components";
import PhotoCard from "../components/PhotoCard";

export default function Slide_Beysu_3({ show }) {
  return (
    <Container show={show}>
      <PhotoCard
        top={true}
        label="Dünyanın en havalı fotosu net."
        mL="17vw"
        mT="45vh"
        date="23 Ağustos 2023"
        img="/slides/photo_5.jpeg"
      />
      <PhotoCard
        top={true}
        label="Çıkıyoduk ama çıkmıyoduk."
        mL="52vw"
        mT="44vh"
        date="1 Kasım 2023"
        img="/slides/photo_4.jpeg"
      />
      <PhotoCard
        top={true}
        label="Şu fotoya saatlerce bakmışımdır."
        mL="35vw"
        mT="44vh"
        date="1 Kasım 2023"
        img="/slides/photo_22.jpeg"
      />
      <Location>Beysupark, Ankara</Location>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 25 : -25)};
  transform: ${(props) =>
    props.show ? "translateY(0px)" : "translateY(-50px)"};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
  margin-top: 10px;
`;
