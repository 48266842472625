import React from "react";
import styled from "styled-components";

export default function Slide_Beysu_1({ show }) {
  return (
    <Container show={show}>
      <LabelContainer>
        <Title>İşte her şey burda başladı. ❤️</Title>
        <Subtitle>Senin gülüşünün güzelliğini burada fark ettim.</Subtitle>
      </LabelContainer>
      <Location>Beysupark, Ankara</Location>
    </Container>
  );
}

const LabelContainer = styled.div`
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: left;
  justify-content: center;
  margin-top: 2%;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1%;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: 25;
  z-index: ${(props) => (props.show ? 25 : -25)};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;
const Title = styled.body`
  color: whitesmoke;
  font-size: 5vh;
  text-align: left;
  text-shadow: 0px 1px 50px #000;
  font-weight: 500;
`;
const Subtitle = styled.body`
  color: whitesmoke;
  font-size: 3.5vh;
  font-weight: 300;
  text-shadow: 0px 1px 50px #000;
  text-align: left;
  margin-top: 10px;
  opacity: 0.9;
`;
const Location = styled.body`
  color: whitesmoke;
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  margin-bottom: 1.5vh;
  opacity: 0.9;
  margin-left: 1.5vh;
  text-shadow: 0px 1px 50px #000;
`;
